import React from "react"

import Layout from "../components/Layout"
import FullContainer from "../components/common/FullContainer"
import styled from "styled-components"

const TextContainer = styled.div`
  @media (max-width: 1200px) {
    font-size: 20px;
  }

  @media (max-width: 700px) {
    font-size: 16px;
  }

  @media (max-width: 600px) {
    font-size: 12px;
  }
`

const Insights = () => {
  return (
    <>
      <Layout>
        <FullContainer>
          <h1>Work insights</h1>
          <TextContainer>
            <p>The work has been good</p>
          </TextContainer>
        </FullContainer>
      </Layout>
    </>
  )
}

export default Insights
